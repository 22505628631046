import { Box, useTheme } from '@chakra-ui/react';

const MainContainer = ({ children, withHeader, ...rest }) => {
  const theme = useTheme();
  const navHeight = withHeader ? theme.header.height : 0;
  return (
    <Box height='100%' pt={`${navHeight}px`} {...rest}>
      {children}
    </Box>
  );
};

export default MainContainer;
