import { FC } from 'react';

import {
  Button as ChakraButton,
  IconButton as ChakraIconButton,
  IconButtonProps,
} from '@chakra-ui/react';

// eslint-disable-next-line import/prefer-default-export
export const Button = ChakraButton;

export const IconButton: FC<IconButtonProps> = (props) => (
  <ChakraIconButton {...props} />
);
