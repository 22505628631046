import React from 'react';

import { useTheme, chakra, Box } from '@chakra-ui/react';
import Link from 'next/link';

import { A } from '@/elements';

import useAutoHideHeader from './useAutoHideHeader';

const HeaderContainer = chakra(Box, {
  baseStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
  },
});

const Logo = chakra(Box);

export default function Header() {
  const theme = useTheme();
  const navHeight = theme.header.height;

  const { isAtTop, nav } = useAutoHideHeader({
    navHeight,
  });

  return (
    <HeaderContainer
      ref={nav as any}
      backgroundColor='green.200'
      boxShadow={!isAtTop && 'md'}
      height={`${navHeight}px`}
      p='md'
    >
      <Link href='/' passHref>
        <A href='/'>
          <Logo>COME</Logo>
        </A>
      </Link>
    </HeaderContainer>
  );
}
