import React from 'react';

import { Center } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { Section } from '@/components/Layout';
import Page from '@/components/Pages/Page';
import { Button, H1 } from '@/elements';

const Home = (): JSX.Element => {
  const { push } = useRouter();

  return (
    <Page>
      <Center h='100%'>
        <Section>
          <Center mb={4}>
            <H1 fontWeight='bold'>COME</H1>
          </Center>
          <Center>
            <Button onClick={() => push('/events/create')}>Create Event</Button>
          </Center>
        </Section>
      </Center>
    </Page>
  );
};

export default Home;
