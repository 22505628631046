import React from 'react';

import { Header } from '@/components/Layout';

import MainContainer from '../Layout/Main';

const Page = ({ children, withHeader = false }) => {
  return (
    <>
      {withHeader && <Header />}
      <MainContainer withHeader={withHeader}>{children}</MainContainer>
    </>
  );
};

export default Page;
