import { useEffect, useRef, useState } from 'react';

export default function useAutoHideHeader({
  navHeight,
  speedModifier = 1,
  min = 0,
}: {
  navHeight: number;
  speedModifier?: number;
  min?: number;
}) {
  let lastScrollY = 0;
  let lastOffsetTop = 0;
  let deltaSum = 0;
  let lastDir: any = null;

  const nav = useRef<HTMLElement>();
  const [isAtTop, setIsAtTop] = useState(true);

  const scrollHandler = () => {
    const newScrollY = window.scrollY;
    const deltaY = lastScrollY - newScrollY;
    const dir = deltaY > 0 ? 'up' : 'down';

    let oT;

    if (lastDir !== dir) {
      deltaSum = deltaY;
    } else {
      deltaSum += deltaY;
    }

    if (deltaSum > navHeight && deltaY > 0) {
      oT = Math.min(min, lastOffsetTop + deltaY);
    } else {
      const max = newScrollY < 0 ? 0 : -navHeight;
      oT = Math.max(max, lastOffsetTop + deltaY * speedModifier);
    }
    oT = Math.min(min, oT);

    nav.current.style.top = `${oT}px`;
    setIsAtTop(newScrollY <= navHeight / speedModifier);

    lastDir = dir;
    lastOffsetTop = oT;
    lastScrollY = newScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return {
    isAtTop,
    nav,
  };
}
